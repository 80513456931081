<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Go to backoffice': 'Ir para o escritório',
      'Menu': 'Menu',
      'Dashboard': 'Painel',
      'Store': 'Loja',
      'Products': 'Produtos',
      'Cart': 'Carrinho',
      'My Orders': 'Meus Pedidos',
      'Categories': 'Categorias',
    },
    es: {
      'Go to backoffice': 'Ir a backoffice',
      'Menu': 'Menu',
      'Dashboard': 'Panel',
      'Store': 'Tienda',
      'Products': 'Productos',
      'Cart': 'Cesta',
      'My Orders': 'Mis Pedidos',
      'Categories': 'Categorias',
    }
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      loading: true,
      companies: []
    };
  },
  mounted: function() {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }

    this.getCompanies()
  },
  methods: {
    lightSidebar() {
      document.body.setAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-topbar", "dark");
    },
    iconSidebar() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar", "colored");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
    },
    coloredSidebar() {
      document.body.setAttribute("data-sidebar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    getCompanies() {
      api
        .get('partners/companies')
        .then((response) => {
          if (response.data.status == 'success') {
            this.companies = response.data.list
          }

          this.loading = false
        })
    }
  }
};
</script>

<template>
  <div id="sidebar-menu">
    <ul id="side-menu" class="metismenu list-unstyled row">
      <li class="pt-1">
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <i class="bx bx-home-circle"></i>
          <span class="notranslate">{{ t('Go to backoffice') }}</span>
        </router-link>
      </li>
    </ul>
    <ul id="side-menu" class="metismenu list-unstyled row pt-2 px-2">

      <li v-for="(com,id) in companies" :key="id" class="col-6 col-md-12 p-2">
        <router-link tag="a" :to="'/partners/companies/' + com.id" class="side-nav-link-ref border-0 p-0">
          <img class="company-cover w-100" :src="com.cover" />
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.company-cover {
  max-height: 300px;
  border-radius: 0.25rem;
}
</style>