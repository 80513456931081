<script>
import Layout from "@/router/layouts/company";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Companies': 'Empresas',
    },
    es: {
      'Companies': 'Compañías',
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: true,

      company: {
        id: this.$route.params.id,
        type: '',
        name: '...',
        cover: '',
        url: '',
        content: '',
      }
    };
  },
  methods: {
    getCompany() {
      api
        .get('partners/companies/' + this.company.id)
        .then((response) => {
          if (response.data.status == 'success') {
            this.company = response.data.company
          }

          this.loading = false
        })
    }
  },
  mounted() {
    this.getCompany()
  },
  watch: {
    '$route.params.id': function (v) {
      this.loading = true
      this.company.id = v
      this.getCompany()
    }
  }
};
</script>

<template>
  <Layout>
    <div v-if="loading" class="card">
      <div class="card-body text-center">
        <b-spinner small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
      </div>
    </div>
    <div v-else>
      <div class="d-flex">
        <div class="company-page">
          <div v-if="company.iframe == 'no'" class="bg-soft-dark w-100 h-100 d-flex">
            <div class="align-self-center text-center w-100 p-4">
              <img style="max-width: 300px;" class="w-100 rounded" :src="company.cover" /><br><br>
              <a class="btn btn-dark btn-lg" target="_blank" :href="company.url">CLIQUE PARA ACESSAR</a>
            </div>
          </div>
          <iframe
            v-else
            :src="company.url"
            frameborder="0" sandbox="allow-forms allow-scripts"
          ></iframe>
        </div>
        <div class="company-content d-none d-sm-block">
          <img v-if="company.cover" class="company-cover w-100" :src="company.cover" />
          <div class="card">
            <div class="card-body" v-html="company.content">
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.company-page {
  margin-bottom: 20px;
  width: 100%;
  height: calc(100vh - 165px);
}
.company-page iframe {
  display: block;
  border-radius: 0.25rem;
  width: 100%;
  height: calc(100vh - 165px);
}
.company-cover {
  max-width: 250px;
  max-height: 200px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.company-content {
  margin-left: 20px;
  max-width: 250px;
}
.company-content .card-body {
  height: calc(100vh - 264px);
  overflow-y: scroll;
}
</style>